.grid-dashboard {

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-gap: 1rem;
    max-height: 100vh;

    .cell {
        min-height: 330px;
        
        &:nth-child(1) {
            grid-row: 1 / 5;
        }

        &:nth-child(2) {
            grid-row: 1 / -1;
        }

        &:nth-child(3) {
            grid-row: 1 / -1;
        }

        &:nth-child(4) {
            grid-row: 1 / -1;
        }

        &:nth-child(5) {
            grid-row: 5 / -1;
        }

        &:nth-child(6) {
            grid-row: 6 / -1;
        }
    }

}

@media(max-width: 1199px) {

    .grid-dashboard .cell {

        &:nth-child(1) {
            grid-row: 1 / 3;
            grid-column: 1 / 3;
        }

        &:nth-child(2) {
            grid-row: 7 / -1;
            grid-column: 1 / 3;
        }

        &:nth-child(3) {
            grid-row: 5 / 7;
            grid-column: 1 / 3;
        }

        &:nth-child(4) {
            grid-row: 1 / 7;
            grid-column: 3 / -1;
        }

        &:nth-child(5) {
            grid-row: 3 / 5;
            grid-column: 1 / 3;
        }

        &:nth-child(6) {
            grid-row: 7 / -1;
            grid-column: 3 / -1;
        }
    }

}

@media(max-width: 639px) {

    .grid-dashboard  {

        display: block;

        .cell {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

}