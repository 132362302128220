#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
  z-index: 9999; /* Make sure loader is on top of everything */
  display: flex;
  justify-content: center;
  align-items: center;
}

#loader {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid #3498db; /* Blue border for animation */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Animation for spinning */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
