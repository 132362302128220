@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply transition-colors duration-200;
}

.neon-text {
  color: #5fa5f9;
  text-shadow: 0 0 5px #5fa5f9, 0 0 10px #5fa5f9, 0 0 20px #5fa5f9,
    0 0 40px #5fa5f9, 0 0 80px #5fa5f9;
}

.neon-bg {
  background-color: #5fa5f9;
  box-shadow: 0 0 5px #5fa5f9, 0 0 10px #5fa5f9, 0 0 20px #5fa5f9,
    0 0 40px #5fa5f9, 0 0 80px #5fa5f9;
}

.truncate-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3rem; /* Adjust based on line height */
  cursor: pointer;
}

.time-range {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #777777; /* Change to your desired color */
}

.time {
  position: relative;
}

.time sup {
  font-size: 10px;
  position: relative;
  top: -0.5em;
}

.dash {
  margin: 0 4px;
}

.bg-video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

html {
  scroll-behavior: smooth;
}

.leaflet-control-attribution {
  display: none !important;
}
