/* custom-toast.css */

.custom-toast-container {
  z-index: 9999;
}

.custom-toast {
  border-radius: 12px !important;
  background: linear-gradient(135deg, #84baf8, #e23bb8) !important;
  color: white !important;
  font-weight: 500;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1) !important;
}

.custom-toast-body {
  padding: 16px !important;
  font-size: 16px !important;
}

.custom-toast-progress {
  background: #d2136a !important;
  height: 4px !important;
}
