.scroll-here {
    overflow: auto;
    height: 100%;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
  
::-webkit-scrollbar-track {
    background-color: #fefefe;
    background-clip: content-box;
    border-radius: 100px;
}
  
::-webkit-scrollbar-thumb {
    background-color: #f6f5fe;
    border-radius: 100px;
// border: 2px solid transparent;
// background-clip: content-box;
}

// gantt non-reliable class. It can crash
._2k9Ys {
    height: 7px !important;
}

.gantt-scrollbar [dir="ltr"] {

    cursor: pointer;

    &::-webkit-scrollbar {
        width: 2px;
        height: 7px;
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 9px;
        height: 2px;
        width: 2px;
        cursor: pointer !important;
        cursor: pointer;
    }

    &:hover::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.4);    
    }
        
    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        background-clip: content-box;
        border-radius: 100px;
        cursor: pointer;
    }
}

.c-viewport::-webkit-scrollbar {
    width: 0;
    height: 0;
}





.sidenav-menu::-webkit-scrollbar {
    width: 5px;
}
  
.sidenav-menu::-webkit-scrollbar-track {
    background-color: transparent;
    background-clip: content-box;
    border-radius: 100px;
}
  
.sidenav-menu::-webkit-scrollbar-thumb {
    background-color: rgb(119, 103, 218);
    border-radius: 100px;
}



.hidden-x-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}
  
.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
